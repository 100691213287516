import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Particles from 'react-tsparticles';

import setGAEvent from './config/firebaseConfig';

import Home from './container/Home';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;

    body {
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: 'Poiret One', cursive;
      background-color: #000;
      color: #fff;

      * {
        box-sizing: border-box;
      }
    }
  }
`;

const HomeWrapper = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: auto;
`;

const App = () => {
  useEffect(() => {
    setGAEvent('load_home_page');
  }, []);

  return (
    <>
      <HomeWrapper>
        <Particles
          id="tsparticles"
          options={{
            background: {
              color: {
                value: '#000',
              },
            },
            fpsLimit: 60,
            interactivity: {
              events: {
                onHover: {
                  enable: true,
                  mode: 'repulse',
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 40,
                },
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: 'random',
              },
              links: {
                blink: 'enable',
                color: 'random',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 60,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'line',
              },
              size: {
                random: true,
                value: 5,
              },
            },
            detectRetina: true,
          }}
        />
      </HomeWrapper>

      <GlobalStyle />
      <Home />
    </>
  );
};

export default App;
