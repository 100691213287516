/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import LinkComponent from '../../components/Link';

import Wrapper from '../styles';
import GithubIcon from '../../assets/svg/part+1+github-1320568339880199515_0.svg';
import LinkedinIcon from '../../assets/svg/linkedin+logo+network+social+icon-1320086343821534645_0.svg';

const Home = () => (
  <Wrapper>
    <header>
      <h1>Guilherme Milfont Moreno</h1>
      <h2>"Milfront"</h2>
    </header>
    <section>
      <p>Hello everybody, I am a Front-End developer with 11 years of exeprience</p>
      <p>Currently working with React Native on Zé Delivery</p>
      <p>Experience with Javascript (React, Typescript), Css (BEM, RCSS), HTML</p>
      <p>If you want to talk to me, please contact:</p>
      <LinkComponent url="https://github.com/guialemao" eventName="click_contact_github">
        <img src={GithubIcon} alt="Github" />
        Github
      </LinkComponent>
      <LinkComponent url="https://www.linkedin.com/in/guilherme-m-02051578/" eventName="click_contact_linkedin">
        <img src={LinkedinIcon} alt="Linkdin" />
        Linkedin
      </LinkComponent>
    </section>
  </Wrapper>
);

export default Home;
