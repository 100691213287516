import * as firebase from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

firebase.initializeApp({
  apiKey: 'AIzaSyBMhWWc2uLOASMe2xNygh78GMJFJpKbnu4',
  authDomain: 'milfront-d34c0.firebaseapp.com',
  projectId: 'milfront-d34c0',
  storageBucket: 'milfront-d34c0.appspot.com',
  messagingSenderId: '578747922457',
  appId: '1:578747922457:web:7bf17d8cad23dc8fb45288',
  measurementId: 'G-ERLL90TL13',
});

const analytics = getAnalytics();

const setGAEvent = (eventName, params = null) => {
  logEvent(analytics, eventName, params);
};

export default setGAEvent;
