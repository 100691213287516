"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadFull = void 0;
const slim_1 = require("./slim");
const Trail_1 = require("./Interactions/External/Trail");
const Tilt_1 = require("./Updaters/Tilt");
const Wobble_1 = require("./Updaters/Wobble");
const Roll_1 = require("./Updaters/Roll");
const Absorbers_1 = require("./Plugins/Absorbers");
const Emitters_1 = require("./Plugins/Emitters");
const PolygonMask_1 = require("./Plugins/PolygonMask");
async function loadFull(tsParticles) {
    await (0, slim_1.loadSlim)(tsParticles);
    await (0, Trail_1.loadExternalTrailInteraction)(tsParticles);
    await (0, Roll_1.loadRollUpdater)(tsParticles);
    await (0, Tilt_1.loadTiltUpdater)(tsParticles);
    await (0, Wobble_1.loadWobbleUpdater)(tsParticles);
    await (0, Absorbers_1.loadAbsorbersPlugin)(tsParticles);
    await (0, Emitters_1.loadEmittersPlugin)(tsParticles);
    await (0, PolygonMask_1.loadPolygonMaskPlugin)(tsParticles);
}
exports.loadFull = loadFull;
