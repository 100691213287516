import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0 10px;
  text-align: center;
  flex-direction: "column";
  justify-content: "center";

  h1 {
    font-size: 2.8em;
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 40px;
  }

  p {
    font-size: 1.2em;
  }

  a {
    display: block;
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;

    img {
      margin-right: 10px;
    }
  }

  footer {
    border-top: 1px solid #fff;
  }

  /* @media (min-width: 767px) {
    h1 {
      margin-bottom: 50px;
    }
  }

  @media (min-width: 997px) {
    width: 998px;
    padding: 0;

    h1 {
      margin-bottom: 20px;
    }
  } */
`;

export default Wrapper;
