/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const Application = () => <App />;

const Root = document.getElementById('root');

ReactDOM.render(<Application />, Root);
