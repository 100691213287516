"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalInteractorBase = void 0;
class ExternalInteractorBase {
    constructor(container) {
        this.container = container;
        this.type = 0;
    }
}
exports.ExternalInteractorBase = ExternalInteractorBase;
